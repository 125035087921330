import { StatusBar } from 'expo-status-bar';
import { useEffect, useState, useRef } from 'react';
import { StyleSheet, Text, View } from 'react-native';

const apiDomain = "https://api.listen.li"
// const apiDomain = "http://localhost:8080"

async function uploadAudio(blob) {
  const formData = new FormData()
  formData.append("record", blob)


  const response = await fetch(
    `${apiDomain}/record`,
    {
      method: 'POST',
      body: formData
    }
  )
}

function recordStart(recorder) {
    recorder.start();
    console.log(recorder.state);
    console.log("recorder started");
}

function recordStop(recorder) {
    recorder.stop();
    console.log(recorder.state);
    console.log("recorder started");
}

function audioPlay(audioEl) {
  console.log(audioEl)
  audioEl.play()
  console.log("audio played")
}

export default function App() {
  const [recorder, setRecorder] = useState(null)
  const [url, setUrl] = useState(null)
  const audioRef = useRef();

  useEffect(() => {
    setInterval(async () => {
      const response = await fetch(
        `${apiDomain}/reload`,
        {
          method: 'GET'
        }
      )
      
      const json = await response.json();

      if(json.result === null) {
        return;
      }

      alert(json.result.text);
    }, 5000);

    let mediaRecorder;
    let chunks = [];

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      console.log("getUserMedia supported.");
      navigator.mediaDevices.getUserMedia({
        audio: true,
      }).then((stream) => {
        mediaRecorder = new MediaRecorder(stream);
        setRecorder(mediaRecorder);

        mediaRecorder.ondataavailable = (e) => {
          chunks.push(e.data);
        };

        mediaRecorder.onstop = (e) => {
          console.log("recorder stopped");

          const blob = new Blob(chunks, { type: "video/webm" });
          chunks = [];

          const audioURL = window.URL.createObjectURL(blob);
          setUrl(audioURL)

          uploadAudio(blob);


          // a 태그를 생성하고 속성을 설정합니다.
          // var a = document.createElement("a");
          // a.style.display = "none";
          // a.href = audioURL;
          // a.download = "audio.webm"; // 다운로드될 파일의 이름을 설정합니다.

          // a 태그를 문서에 추가합니다.
          // document.body.appendChild(a);

          // a 태그를 클릭하여 다운로드를 시작합니다.
          // a.click();

          // a 태그와 URL 객체를 정리합니다.
          // window.URL.revokeObjectURL(audioURL);
          // document.body.removeChild(a);
        };
      }).catch((err) => {
        console.error(`The following getUserMedia error occurred: ${err}`);
      });
    } else {
      console.log("getUserMedia not supported on your browser!");
    }

  }, []);

  return (
    <View
      style={styles.container}
    >
      <StatusBar style="auto" />
      <button style={{userSelect: "none"}}
        onMouseDown={recordStart.bind(this, recorder)}
        onMouseUp={recordStop.bind(this, recorder)}
        onTouchStart={recordStart.bind(this, recorder)}
        onTouchEnd={recordStop.bind(this, recorder)}
        onContextMenu={(e) => {e.preventDefault()}}
      >녹음(누른 상태로 말하세요.)</button>
      <button onClick={audioPlay.bind(this, audioRef.current)}>재생</button>
      <audio ref={audioRef} style={{display: "none"}} src={url} controls />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
